import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRHBase } from '../../../../hooks/useRHBase'
// Components
import { Form, Row, Col } from 'react-bootstrap'
import { Scrollbar } from '@olystic/design_system_3'
import { dateFilterMapping } from '../RHTable/dateFilterMapping'

function CreateGroups({ groups, onChangeGroups, baseId }) {
  const { t } = useTranslation()
  const base = useRHBase(baseId)

  function handleChange(e) {
    const { name, checked } = e.target
    const [groupKey, itemKey] = name.split('.')

    onChangeGroups((currentGroups) => {
      const updatedGroups = {
        ...currentGroups,
        [groupKey]: {
          ...currentGroups[groupKey],
          [itemKey]: checked,
        },
      }
      return updatedGroups
    })
  }

  return (
    <>
      <p>{t('component.createGroupsModal.text1')}</p>
      <p>{t('component.createGroupsModal.text2')}</p>
      <p>{t('component.createGroupsModal.text3')}</p>

      <Scrollbar
        style={{
          maxHeight: '55vh',
          marginRight: '-1.5rem',
          paddingRight: '1.5rem',
        }}
      >
        <Row className='mt-3'>
          {Object.keys(groups).map((group) => (
            <Col sm='6' key={group} className='mb-5'>
              <div className='p-3 border bg-secondary bg-opacity-25'>
                <p className='m-0'>{t(`category.${group}`)}</p>
              </div>
              <div>
                {Object.keys(groups[group])
                  .sort((a, b) => {
                    let order =
                      group === 'ps_age' || group === 'ps_anciennete'
                        ? dateFilterMapping[
                            group === 'ps_age' ? 'age' : 'seniority'
                          ][
                            base.base.data.modalities[
                              group === 'ps_age' ? 'age' : 'seniority'
                            ]
                          ].labels
                        : null

                    if (order) {
                      return order.indexOf(a) - order.indexOf(b)
                    }
                    return a.localeCompare(b)
                  })
                  .map((item) => (
                    <div
                      key={item}
                      className='d-flex justify-content-between align-items-center border border-1 border-top-0 p-3'
                    >
                      <p className='m-0 fs-lg'>{item}</p>
                      <Form.Check
                        name={`${group}.${item}`}
                        type='switch'
                        checked={groups[group][item]}
                        onChange={handleChange}
                      />
                    </div>
                  ))}
              </div>
            </Col>
          ))}
        </Row>
      </Scrollbar>
    </>
  )
}

export default CreateGroups
